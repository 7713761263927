@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.ttf?k1rzws') format('truetype'),
    url('fonts/icomoon.woff?k1rzws') format('woff'),
    url('fonts/icomoon.svg?k1rzws#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-discord:before {
  content: '\e909';
}
.icon-error:before {
  content: '\e908';
}
.icon-menu:before {
  content: '\e907';
}
.icon-new-tab:before {
  content: '\e904';
}
.icon-folder-open:before {
  content: '\e905';
}
.icon-directory:before {
  content: '\e905';
}
.icon-category:before {
  content: '\e905';
}
.icon-browse:before {
  content: '\e905';
}
.icon-drawer:before {
  content: '\e901';
}
.icon-box:before {
  content: '\e901';
}
.icon-inbox:before {
  content: '\e901';
}
.icon-archive:before {
  content: '\e901';
}
.icon-category1:before {
  content: '\e901';
}
.icon-bubble:before {
  content: '\e902';
}
.icon-comment:before {
  content: '\e902';
}
.icon-chat:before {
  content: '\e902';
}
.icon-talk:before {
  content: '\e902';
}
.icon-facebook:before {
  content: '\e903';
}
.icon-brand:before {
  content: '\e903';
}
.icon-social:before {
  content: '\e903';
}
.icon-spinner6:before {
  content: '\e97f';
}
.icon-plus:before {
  content: '\ea0a';
}
.icon-checkmark:before {
  content: '\ea10';
}
.icon-arrow-left2:before {
  content: '\ea40';
}
.icon-arrow-right2:before {
  content: '\ea3c';
}
.icon-rss2:before {
  content: '\ea9c';
}
.icon-question-circle:before {
  content: '\e900';
}
.icon-th-large:before {
  content: '\e906';
}
.icon-github:before {
  content: '\eab0';
}
